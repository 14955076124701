import {
	RavenButton,
	RavenDropDown,
	RavenInputField,
	toast,
} from '@ravenpay/raven-bank-ui';
import React, { useContext, useEffect, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import './style/index.css';
import { generateRandomColor } from '../../../utils/helper/color';
import RootState from '../../../redux/types';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie, returnInitial } from '../../../utils/helper/Helper';
import { icons } from '../../../assets/icons';
import { useNavigate } from 'react-router-dom';
import { LOGOUT } from '../../../routes/PrivateGuard';
import OmniSearch from '../../../components/omniSearch';
import { logger } from '../../../services/logger';
import { toggleMenu } from '../../../redux/actions/actions';
import { SET_TOKEN, logoutUser } from '../../../redux/auth';
// import useUser from '../../../hooks/useUser';
import ActionContext from '../../../context/ActionContext';
import UseUser from '../../../hooks/useUser';

interface MyComponentProps {
	toggleTheme: (param: any) => void;
	theme: any;
	back?: boolean;
	onBack?: () => void;
}

const HeaderNavComponent: React.FC<MyComponentProps> = ({
	toggleTheme,
	theme,
	back,
	onBack,
}) => {
	const apiArrowIcon = (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className="img"
		>
			<path
				d="M1.5 10.5L10.5 1.5M10.5 1.5H4.5M10.5 1.5V7.5"
				stroke="#755AE2"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [profileImg, setProfileImg] = useState(false);
	const [reload, setReload] = useState(false);
	const [showDrop, setShowDrop] = useState(false);
	const actionCtx = useContext(ActionContext);
	const lightIcon = (
		<svg
			className="img"
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.18971 5.12364C5.29204 5.13091 5.3939 5.14377 5.49482 5.16216C6.09336 5.28918 6.52225 5.61832 6.69406 6.22248C6.75335 6.43022 6.76439 6.65202 6.79382 6.86798C6.81027 6.98677 6.87345 7.05775 6.97083 7.05364C7.0615 7.04996 7.11971 6.97812 7.12771 6.85889C7.13355 6.77363 7.13572 6.68794 7.14502 6.6029C7.19414 6.16276 7.34973 5.77477 7.70526 5.48935C8.00821 5.24677 8.36093 5.14615 8.74156 5.12321C8.86274 5.11585 8.93263 5.05375 8.93501 4.95464C8.93761 4.85207 8.86339 4.78434 8.74026 4.77396C8.66193 4.76746 8.58316 4.76379 8.50591 4.75037C8.04824 4.67139 7.66588 4.46906 7.41032 4.06657C7.22314 3.77206 7.15433 3.44358 7.14762 3.09995C7.14546 2.98591 7.12057 2.88984 7.00415 2.8418H6.92192C6.83537 2.89135 6.7936 2.96319 6.79057 3.06641C6.78721 3.18967 6.77564 3.31256 6.75595 3.43428C6.68216 3.89151 6.47183 4.26544 6.07107 4.51623C5.80426 4.68264 5.50975 4.76184 5.19664 4.7759C5.07416 4.78153 4.99474 4.85402 4.99712 4.95291C4.9995 5.0518 5.07286 5.11218 5.18971 5.12364Z"
				fill="#C8C9C7"
			/>
			<path
				d="M13.5512 7.686C13.5152 7.67631 13.4785 7.66979 13.4413 7.66652C13.2485 7.63969 13.0518 7.62714 12.8633 7.58494C11.5899 7.30082 10.8031 6.44975 10.6157 5.15378C10.5834 4.9309 10.5772 4.70434 10.5596 4.47929C10.5503 4.36266 10.4832 4.29082 10.3833 4.29276C10.2798 4.29493 10.221 4.37369 10.2091 4.50677C10.1842 4.78549 10.1727 5.06744 10.1214 5.34161C10.0052 5.96395 9.73064 6.50861 9.24441 6.92668C8.68028 7.41205 8.01098 7.63038 7.27525 7.66739C7.13373 7.67453 7.06015 7.73577 7.05885 7.84807C7.05756 7.96038 7.13135 8.02313 7.27049 8.03417C7.42694 8.0465 7.5849 8.0543 7.73941 8.0807C8.30657 8.17829 8.82136 8.39511 9.25544 8.78115C9.8278 9.2914 10.0842 9.95594 10.1669 10.6997C10.1872 10.883 10.1885 11.0675 10.2048 11.2523C10.2128 11.3473 10.2746 11.4017 10.3686 11.4038C10.4625 11.406 10.522 11.3536 10.5395 11.2599C10.545 11.2262 10.5486 11.1921 10.5503 11.158C10.5661 10.9537 10.5702 10.7469 10.5992 10.545C10.6858 9.94253 10.9002 9.39419 11.3244 8.94561C11.8234 8.41827 12.4539 8.15513 13.162 8.05841C13.2801 8.04196 13.4 8.04391 13.5194 8.02833C13.606 8.01664 13.675 7.96709 13.6822 7.87144C13.6893 7.7758 13.6408 7.71434 13.5512 7.686Z"
				fill="#272823"
			/>
			<path
				d="M8.18092 10.6035C8.05844 10.5914 7.9351 10.5845 7.81305 10.5644C6.96545 10.4235 6.34981 9.89571 6.16328 9.04962C6.11416 8.8276 6.10897 8.5952 6.08279 8.36842C6.07088 8.26542 6.01159 8.20461 5.92222 8.20007C5.82723 8.19509 5.76036 8.25286 5.74175 8.35846C5.73767 8.38897 5.73522 8.41967 5.7344 8.45043C5.72596 8.56988 5.72379 8.68997 5.708 8.80856C5.63724 9.34369 5.42669 9.80785 4.9952 10.1502C4.61392 10.4531 4.16772 10.5773 3.68885 10.6024C3.56096 10.6092 3.48825 10.6689 3.48544 10.771C3.48241 10.877 3.55771 10.943 3.69015 10.9526C3.76848 10.9584 3.84681 10.9653 3.92493 10.9742C4.84633 11.0805 5.50004 11.6842 5.6684 12.5956C5.70432 12.7904 5.70843 12.9897 5.72834 13.1868C5.73807 13.2822 5.80602 13.3541 5.89258 13.3528C5.98584 13.3515 6.04622 13.3015 6.06569 13.2087C6.07086 13.1784 6.07418 13.1478 6.07564 13.1171C6.08993 12.9572 6.09231 12.7951 6.12 12.6376C6.24681 11.9138 6.63004 11.3888 7.32552 11.1125C7.57141 11.0172 7.83132 10.9632 8.09479 10.9526C8.24367 10.945 8.31746 10.8786 8.31573 10.7279C8.31291 10.6654 8.27137 10.6126 8.18092 10.6035Z"
				fill="#272823"
			/>
			<path
				d="M5.18971 5.12364C5.29204 5.13091 5.3939 5.14377 5.49482 5.16216C6.09336 5.28918 6.52225 5.61832 6.69406 6.22248C6.75335 6.43022 6.76439 6.65202 6.79382 6.86798C6.81027 6.98677 6.87345 7.05775 6.97083 7.05364C7.0615 7.04996 7.11971 6.97812 7.12771 6.85889C7.13355 6.77363 7.13572 6.68794 7.14502 6.6029C7.19414 6.16276 7.34973 5.77477 7.70526 5.48935C8.00821 5.24677 8.36093 5.14615 8.74156 5.12321C8.86274 5.11585 8.93263 5.05375 8.93501 4.95464C8.93761 4.85207 8.86339 4.78434 8.74026 4.77396C8.66193 4.76746 8.58316 4.76379 8.50591 4.75037C8.04824 4.67139 7.66588 4.46906 7.41032 4.06657C7.22314 3.77206 7.15433 3.44358 7.14762 3.09995C7.14546 2.98591 7.12057 2.88984 7.00415 2.8418H6.92192C6.83537 2.89135 6.7936 2.96319 6.79057 3.06641C6.78721 3.18967 6.77564 3.31256 6.75595 3.43428C6.68216 3.89151 6.47183 4.26544 6.07107 4.51623C5.80426 4.68264 5.50975 4.76184 5.19664 4.7759C5.07416 4.78153 4.99474 4.85402 4.99712 4.95291C4.9995 5.0518 5.07286 5.11218 5.18971 5.12364Z"
				fill="#C8C9C7"
			/>
			<path
				d="M13.5512 7.686C13.5152 7.67631 13.4785 7.66979 13.4413 7.66652C13.2485 7.63969 13.0518 7.62714 12.8633 7.58494C11.5899 7.30082 10.8031 6.44975 10.6157 5.15378C10.5834 4.9309 10.5772 4.70434 10.5596 4.47929C10.5503 4.36266 10.4832 4.29082 10.3833 4.29276C10.2798 4.29493 10.221 4.37369 10.2091 4.50677C10.1842 4.78549 10.1727 5.06744 10.1214 5.34161C10.0052 5.96395 9.73064 6.50861 9.24441 6.92668C8.68028 7.41205 8.01098 7.63038 7.27525 7.66739C7.13373 7.67453 7.06015 7.73577 7.05885 7.84807C7.05756 7.96038 7.13135 8.02313 7.27049 8.03417C7.42694 8.0465 7.5849 8.0543 7.73941 8.0807C8.30657 8.17829 8.82136 8.39511 9.25544 8.78115C9.8278 9.2914 10.0842 9.95594 10.1669 10.6997C10.1872 10.883 10.1885 11.0675 10.2048 11.2523C10.2128 11.3473 10.2746 11.4017 10.3686 11.4038C10.4625 11.406 10.522 11.3536 10.5395 11.2599C10.545 11.2262 10.5486 11.1921 10.5503 11.158C10.5661 10.9537 10.5702 10.7469 10.5992 10.545C10.6858 9.94253 10.9002 9.39419 11.3244 8.94561C11.8234 8.41827 12.4539 8.15513 13.162 8.05841C13.2801 8.04196 13.4 8.04391 13.5194 8.02833C13.606 8.01664 13.675 7.96709 13.6822 7.87144C13.6893 7.7758 13.6408 7.71434 13.5512 7.686Z"
				fill="#272823"
			/>
			<path
				d="M8.18092 10.6035C8.05844 10.5914 7.9351 10.5845 7.81305 10.5644C6.96545 10.4235 6.34981 9.89571 6.16328 9.04962C6.11416 8.8276 6.10897 8.5952 6.08279 8.36842C6.07088 8.26542 6.01159 8.20461 5.92222 8.20007C5.82723 8.19509 5.76036 8.25286 5.74175 8.35846C5.73767 8.38897 5.73522 8.41967 5.7344 8.45043C5.72596 8.56988 5.72379 8.68997 5.708 8.80856C5.63724 9.34369 5.42669 9.80785 4.9952 10.1502C4.61392 10.4531 4.16772 10.5773 3.68885 10.6024C3.56096 10.6092 3.48825 10.6689 3.48544 10.771C3.48241 10.877 3.55771 10.943 3.69015 10.9526C3.76848 10.9584 3.84681 10.9653 3.92493 10.9742C4.84633 11.0805 5.50004 11.6842 5.6684 12.5956C5.70432 12.7904 5.70843 12.9897 5.72834 13.1868C5.73807 13.2822 5.80602 13.3541 5.89258 13.3528C5.98584 13.3515 6.04622 13.3015 6.06569 13.2087C6.07086 13.1784 6.07418 13.1478 6.07564 13.1171C6.08993 12.9572 6.09231 12.7951 6.12 12.6376C6.24681 11.9138 6.63004 11.3888 7.32552 11.1125C7.57141 11.0172 7.83132 10.9632 8.09479 10.9526C8.24367 10.945 8.31746 10.8786 8.31573 10.7279C8.31291 10.6654 8.27137 10.6126 8.18092 10.6035Z"
				fill="#272823"
			/>
		</svg>
	);
	const lightIconTwo = (
		<svg
			className="img"
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5.18983 5.12364C5.29216 5.13091 5.39402 5.14377 5.49494 5.16216C6.09348 5.28918 6.52237 5.61832 6.69419 6.22248C6.75348 6.43022 6.76451 6.65202 6.79394 6.86798C6.81039 6.98677 6.87357 7.05775 6.97095 7.05364C7.06162 7.04996 7.11983 6.97812 7.12783 6.85889C7.13368 6.77363 7.13584 6.68794 7.14515 6.6029C7.19427 6.16276 7.34985 5.77477 7.70538 5.48935C8.00833 5.24677 8.36105 5.14615 8.74168 5.12321C8.86286 5.11585 8.93276 5.05375 8.93514 4.95464C8.93773 4.85207 8.86351 4.78434 8.74038 4.77396C8.66205 4.76746 8.58328 4.76379 8.50603 4.75037C8.04836 4.67139 7.666 4.46906 7.41044 4.06657C7.22326 3.77206 7.15445 3.44358 7.14774 3.09995C7.14558 2.98591 7.12069 2.88984 7.00427 2.8418H6.92205C6.83549 2.89135 6.79373 2.96319 6.7907 3.06641C6.78733 3.18967 6.77576 3.31256 6.75607 3.43428C6.68228 3.89151 6.47195 4.26544 6.0712 4.51623C5.80438 4.68264 5.50988 4.76184 5.19676 4.7759C5.07428 4.78153 4.99486 4.85402 4.99724 4.95291C4.99962 5.0518 5.07298 5.11218 5.18983 5.12364Z"
				fill="#8B8B8B"
			/>
			<path
				d="M13.5514 7.68575C13.5154 7.67606 13.4786 7.66955 13.4414 7.66628C13.2486 7.63945 13.0519 7.62689 12.8635 7.5847C11.59 7.30058 10.8032 6.44951 10.6158 5.15354C10.5836 4.93066 10.5773 4.7041 10.5598 4.47905C10.5505 4.36241 10.4834 4.29057 10.3834 4.29252C10.28 4.29468 10.2211 4.37345 10.2092 4.50653C10.1843 4.78524 10.1728 5.0672 10.1216 5.34137C10.0054 5.96371 9.73076 6.50837 9.24453 6.92644C8.6804 7.4118 8.0111 7.63014 7.27537 7.66714C7.13385 7.67428 7.06028 7.73552 7.05898 7.84783C7.05768 7.96014 7.13147 8.02289 7.27061 8.03393C7.42706 8.04626 7.58502 8.05405 7.73953 8.08045C8.30669 8.17804 8.82148 8.39487 9.25557 8.78091C9.82792 9.29116 10.0843 9.9557 10.167 10.6994C10.1873 10.8827 10.1886 11.0673 10.2049 11.2521C10.2129 11.3471 10.2748 11.4014 10.3687 11.4036C10.4626 11.4057 10.5221 11.3534 10.5396 11.2597C10.5451 11.2259 10.5487 11.1919 10.5505 11.1578C10.5662 10.9535 10.5704 10.7466 10.5994 10.5447C10.6859 9.94228 10.9004 9.39395 11.3245 8.94537C11.8235 8.41802 12.454 8.15489 13.1621 8.05816C13.2802 8.04172 13.4001 8.04366 13.5196 8.02808C13.6061 8.0164 13.6751 7.96685 13.6823 7.8712C13.6894 7.77556 13.641 7.7141 13.5514 7.68575Z"
				fill="#8B8B8B"
			/>
			<path
				d="M8.18104 10.6035C8.05856 10.5913 7.93522 10.5844 7.81317 10.5643C6.96557 10.4234 6.34993 9.89565 6.1634 9.04956C6.11428 8.82754 6.10909 8.59513 6.08291 8.36836C6.07101 8.26535 6.01171 8.20455 5.92234 8.2C5.82735 8.19503 5.76048 8.2528 5.74187 8.3584C5.73779 8.38891 5.73534 8.4196 5.73452 8.45037C5.72608 8.56982 5.72391 8.68991 5.70812 8.8085C5.63736 9.34363 5.42681 9.80779 4.99532 10.1501C4.61404 10.4531 4.16784 10.5773 3.68897 10.6024C3.56108 10.6091 3.48837 10.6688 3.48556 10.7709C3.48253 10.877 3.55784 10.943 3.69027 10.9525C3.7686 10.9583 3.84694 10.9653 3.92505 10.9741C4.84645 11.0804 5.50017 11.6841 5.66852 12.5956C5.70444 12.7903 5.70855 12.9896 5.72846 13.1867C5.7382 13.2822 5.80614 13.354 5.8927 13.3527C5.98596 13.3514 6.04634 13.3014 6.06581 13.2086C6.07098 13.1783 6.0743 13.1477 6.07577 13.1171C6.09005 12.9572 6.09243 12.7951 6.12013 12.6375C6.24693 11.9137 6.63016 11.3887 7.32564 11.1124C7.57153 11.0172 7.83144 10.9631 8.09491 10.9525C8.24379 10.9449 8.31758 10.8785 8.31585 10.7279C8.31304 10.6654 8.27149 10.6126 8.18104 10.6035Z"
				fill="#8B8B8B"
			/>
			<path
				d="M5.18983 5.12364C5.29216 5.13091 5.39402 5.14377 5.49494 5.16216C6.09348 5.28918 6.52237 5.61832 6.69419 6.22248C6.75348 6.43022 6.76451 6.65202 6.79394 6.86798C6.81039 6.98677 6.87357 7.05775 6.97095 7.05364C7.06162 7.04996 7.11983 6.97812 7.12783 6.85889C7.13368 6.77363 7.13584 6.68794 7.14515 6.6029C7.19427 6.16276 7.34985 5.77477 7.70538 5.48935C8.00833 5.24677 8.36105 5.14615 8.74168 5.12321C8.86286 5.11585 8.93276 5.05375 8.93514 4.95464C8.93773 4.85207 8.86351 4.78434 8.74038 4.77396C8.66205 4.76746 8.58328 4.76379 8.50603 4.75037C8.04836 4.67139 7.666 4.46906 7.41044 4.06657C7.22326 3.77206 7.15445 3.44358 7.14774 3.09995C7.14558 2.98591 7.12069 2.88984 7.00427 2.8418H6.92205C6.83549 2.89135 6.79373 2.96319 6.7907 3.06641C6.78733 3.18967 6.77576 3.31256 6.75607 3.43428C6.68228 3.89151 6.47195 4.26544 6.0712 4.51623C5.80438 4.68264 5.50988 4.76184 5.19676 4.7759C5.07428 4.78153 4.99486 4.85402 4.99724 4.95291C4.99962 5.0518 5.07298 5.11218 5.18983 5.12364Z"
				fill="#8B8B8B"
			/>
			<path
				d="M13.5514 7.68575C13.5154 7.67606 13.4786 7.66955 13.4414 7.66628C13.2486 7.63945 13.0519 7.62689 12.8635 7.5847C11.59 7.30058 10.8032 6.44951 10.6158 5.15354C10.5836 4.93066 10.5773 4.7041 10.5598 4.47905C10.5505 4.36241 10.4834 4.29057 10.3834 4.29252C10.28 4.29468 10.2211 4.37345 10.2092 4.50653C10.1843 4.78524 10.1728 5.0672 10.1216 5.34137C10.0054 5.96371 9.73076 6.50837 9.24453 6.92644C8.6804 7.4118 8.0111 7.63014 7.27537 7.66714C7.13385 7.67428 7.06028 7.73552 7.05898 7.84783C7.05768 7.96014 7.13147 8.02289 7.27061 8.03393C7.42706 8.04626 7.58502 8.05405 7.73953 8.08045C8.30669 8.17804 8.82148 8.39487 9.25557 8.78091C9.82792 9.29116 10.0843 9.9557 10.167 10.6994C10.1873 10.8827 10.1886 11.0673 10.2049 11.2521C10.2129 11.3471 10.2748 11.4014 10.3687 11.4036C10.4626 11.4057 10.5221 11.3534 10.5396 11.2597C10.5451 11.2259 10.5487 11.1919 10.5505 11.1578C10.5662 10.9535 10.5704 10.7466 10.5994 10.5447C10.6859 9.94228 10.9004 9.39395 11.3245 8.94537C11.8235 8.41802 12.454 8.15489 13.1621 8.05816C13.2802 8.04172 13.4001 8.04366 13.5196 8.02808C13.6061 8.0164 13.6751 7.96685 13.6823 7.8712C13.6894 7.77556 13.641 7.7141 13.5514 7.68575Z"
				fill="#8B8B8B"
			/>
			<path
				d="M8.18104 10.6035C8.05856 10.5913 7.93522 10.5844 7.81317 10.5643C6.96557 10.4234 6.34993 9.89565 6.1634 9.04956C6.11428 8.82754 6.10909 8.59513 6.08291 8.36836C6.07101 8.26535 6.01171 8.20455 5.92234 8.2C5.82735 8.19503 5.76048 8.2528 5.74187 8.3584C5.73779 8.38891 5.73534 8.4196 5.73452 8.45037C5.72608 8.56982 5.72391 8.68991 5.70812 8.8085C5.63736 9.34363 5.42681 9.80779 4.99532 10.1501C4.61404 10.4531 4.16784 10.5773 3.68897 10.6024C3.56108 10.6091 3.48837 10.6688 3.48556 10.7709C3.48253 10.877 3.55784 10.943 3.69027 10.9525C3.7686 10.9583 3.84694 10.9653 3.92505 10.9741C4.84645 11.0804 5.50017 11.6841 5.66852 12.5956C5.70444 12.7903 5.70855 12.9896 5.72846 13.1867C5.7382 13.2822 5.80614 13.354 5.8927 13.3527C5.98596 13.3514 6.04634 13.3014 6.06581 13.2086C6.07098 13.1783 6.0743 13.1477 6.07577 13.1171C6.09005 12.9572 6.09243 12.7951 6.12013 12.6375C6.24693 11.9137 6.63016 11.3887 7.32564 11.1124C7.57153 11.0172 7.83144 10.9631 8.09491 10.9525C8.24379 10.9449 8.31758 10.8785 8.31585 10.7279C8.31304 10.6654 8.27149 10.6126 8.18104 10.6035Z"
				fill="#8B8B8B"
			/>
		</svg>
	);

	const darkIcon = (
		<svg
			className="img"
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.76797 5.76873C9.85507 5.77474 9.94177 5.78565 10.0276 5.80141C10.5385 5.9096 10.9047 6.19091 11.0516 6.70571C11.102 6.88315 11.1115 7.07249 11.1366 7.25664C11.1505 7.35834 11.2046 7.41872 11.2881 7.41526C11.3656 7.41223 11.4151 7.35034 11.4221 7.2495C11.427 7.17679 11.429 7.10365 11.437 7.03116C11.4788 6.65529 11.6116 6.32399 11.9152 6.08034C12.1729 5.87325 12.475 5.78734 12.7998 5.76787C12.9033 5.76159 12.963 5.70858 12.9652 5.62375C12.9673 5.53893 12.9039 5.47855 12.7983 5.46968C12.7317 5.46405 12.6642 5.46081 12.5984 5.44956C12.2078 5.38183 11.8815 5.20871 11.6625 4.86573C11.5028 4.61429 11.4441 4.33406 11.4383 4.04042C11.4364 3.94326 11.4154 3.86103 11.3158 3.82013H11.2457C11.2109 3.83829 11.1819 3.86592 11.162 3.89983C11.1422 3.93375 11.1324 3.97258 11.1336 4.01185C11.1306 4.11683 11.1207 4.2215 11.104 4.32519C11.0408 4.71469 10.8614 5.03473 10.5197 5.2481C10.2919 5.39027 10.0406 5.45778 9.77317 5.47011C9.66865 5.47487 9.60005 5.53676 9.60287 5.62159C9.60568 5.70641 9.66822 5.75878 9.76797 5.76873Z"
				fill="#C8C9C7"
			/>
			<path
				d="M14.1184 9.24556C13.5433 11.3084 11.6276 12.8123 9.36865 12.7606C6.73278 12.6992 4.62405 10.4952 4.67295 7.85914C4.68774 7.06439 4.89935 6.2857 5.28884 5.59278C5.67834 4.89986 6.23356 4.31432 6.90481 3.88857C6.9368 3.86832 6.97382 3.85744 7.01168 3.85718C7.04953 3.85692 7.0867 3.86729 7.11896 3.8871C7.15122 3.90692 7.17727 3.93539 7.19415 3.96927C7.21104 4.00315 7.21808 4.04109 7.21447 4.07878C7.19889 4.2366 7.19103 4.39651 7.19088 4.55852C7.19088 7.21602 9.34484 9.3702 12.0019 9.3702C12.6354 9.37084 13.2628 9.24611 13.8479 9.0032C13.883 8.98905 13.9213 8.98501 13.9586 8.99154C13.9958 8.99807 14.0305 9.01491 14.0587 9.04014C14.0868 9.06537 14.1074 9.09799 14.1179 9.1343C14.1285 9.17061 14.1287 9.20915 14.1184 9.24556Z"
				fill="#272823"
			/>
		</svg>
	);
	const darkIconTwo = (
		<svg
			className="img"
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.76797 5.76892C9.85507 5.77492 9.94177 5.78583 10.0276 5.80159C10.5385 5.90979 10.9047 6.1911 11.0516 6.70589C11.102 6.88333 11.1115 7.07267 11.1366 7.25682C11.1505 7.35853 11.2046 7.4189 11.2881 7.41544C11.3656 7.41241 11.4151 7.35052 11.4221 7.24968C11.427 7.17698 11.429 7.10384 11.437 7.03134C11.4788 6.65547 11.6116 6.32418 11.9152 6.08052C12.1729 5.87343 12.475 5.78753 12.7998 5.76805C12.9033 5.76178 12.963 5.70876 12.9652 5.62393C12.9673 5.53911 12.9039 5.47874 12.7983 5.46986C12.7317 5.46424 12.6642 5.46099 12.5984 5.44974C12.2078 5.38201 11.8815 5.2089 11.6625 4.86592C11.5028 4.61447 11.4441 4.33424 11.4383 4.0406C11.4364 3.94344 11.4154 3.86121 11.3158 3.82031H11.2457C11.2109 3.83847 11.1819 3.8661 11.162 3.90002C11.1422 3.93394 11.1324 3.97277 11.1336 4.01204C11.1306 4.11701 11.1207 4.22168 11.104 4.32537C11.0408 4.71487 10.8614 5.03492 10.5197 5.24828C10.2919 5.39045 10.0406 5.45796 9.77317 5.4703C9.66865 5.47506 9.60005 5.53695 9.60287 5.62177C9.60568 5.7066 9.66822 5.75896 9.76797 5.76892Z"
				fill="white"
			/>
			<path
				d="M14.1186 9.2458C13.5434 11.3087 11.6277 12.8126 9.36877 12.7609C6.7329 12.6994 4.62417 10.4955 4.67307 7.85938C4.68786 7.06463 4.89947 6.28595 5.28897 5.59303C5.67846 4.9001 6.23368 4.31456 6.90494 3.88882C6.93692 3.86856 6.97394 3.85769 7.0118 3.85743C7.04966 3.85717 7.08683 3.86753 7.11908 3.88735C7.15134 3.90716 7.17739 3.93563 7.19428 3.96951C7.21116 4.0034 7.2182 4.04134 7.21459 4.07902C7.19901 4.23685 7.19115 4.39676 7.191 4.55876C7.191 7.21627 9.34497 9.37044 12.002 9.37044C12.6356 9.37109 13.263 9.24636 13.8481 9.00344C13.8831 8.98929 13.9215 8.98525 13.9587 8.99178C13.996 8.99831 14.0306 9.01515 14.0588 9.04038C14.087 9.06562 14.1075 9.09824 14.118 9.13455C14.1286 9.17086 14.1288 9.2094 14.1186 9.2458Z"
				fill="white"
			/>
		</svg>
	);

	const handleLogout = async () => {
		actionCtx?.setLogoutModal(true);
		// const resp = await dispatch(logoutUser({}));

		// if (resp?.payload?.status === 'success') {
		// 	LOGOUT();
		// 	dispatch(SET_TOKEN(''));
		// 	// navigate('/login');
		// 	window.location.replace("/login")
		// } else {
		// 	LOGOUT();
		// 	dispatch(SET_TOKEN(''));
		// 	window.location.replace("/login")
		// }
	};

	function DropMore({ className, style }: any) {
		return (
			<div
				style={{
					top: '8rem',
					zIndex: '4000',
					right: '7rem',
					minWidth: '20rem',
				}}
				className={`more-wrap ${className}`}
			>
				<div
					onClick={() => {
						setShowDrop(false);
						navigate('/dashboard-bankbox/settings-general');
					}}
					className="more_item"
				>
					<figure
						className="profile_drop_img"
						style={{
							backgroundColor: generateRandomColor(''),
							borderRadius: '50%',
							width: '4rem',
							height: '4rem',
						}}
					>
						<p> {returnInitial(profile?.fname + ' ' + profile?.lname)}</p>
					</figure>
					<p>Profile </p>
				</div>
				{/* <div className="more_item">
					<figure>{icons.broken_link}</figure>
					<p>Account Management</p>
				</div> */}
				<div onClick={handleLogout} className="more_item">
					<figure
						className="profile_drop_img"
						style={{
							borderRadius: '50%',
							width: '4rem',
							height: '4rem',
						}}
					>
						{icons.logout}
					</figure>
					<p style={{ color: '#FF0F00' }}>Logout</p>
				</div>
			</div>
		);
	}

	function toggleMobileMenu() {
		dispatch(toggleMenu());
	}

	const { isMenuOpen } = useSelector((state: RootState) => state.general);

	const { profile } = useSelector((state: RootState) => state.wallet);
	//   const defaultImg =
	// const [q, setQ] = useState('');
	// console.log('PROFILE', profile);

	// //use user hook
	const toks = getCookie('token');
	// toks && useUser();

	const backIcon = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="28"
			height="28"
			fill="none"
			viewBox="0 0 28 28"
			className="img dull-bright-filter"
		>
			<path
				stroke="#0B8376"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="1.2"
				d="M14 9.332l-4.666 4.667m0 0L14 18.665M9.334 14h9.333m7 0c0 6.443-5.224 11.666-11.667 11.666S2.334 20.442 2.334 14C2.334 7.555 7.557 2.332 14 2.332c6.444 0 11.667 5.223 11.667 11.667z"
			></path>
		</svg>
	);

	return (
		<div className="dashboard-layout-headernav-wrap ">
			{/* back start */}
			{back && (
				<div
					onClick={() => {
						if (!onBack) {
							if (navigate?.length > 1) {
								navigate(-1);
							}
						} else {
							onBack && onBack();
						}
					}}
					className="back-text-icon-wrap-box"
				>
					<div className="img-wrap">
						<figure className="img-box">{backIcon}</figure>
					</div>
					<p className="text">Back</p>
				</div>
			)}
			{/* back end */}
			{toks && <UseUser />}
			<div
				onClick={() => toggleMobileMenu()}
				className={`mobile-hamburger ${isMenuOpen && 'collapsed'}`}
			>
				<p></p>
				<p></p>
				<p></p>
			</div>
			{/* search box start */}
			<div className="search-box">
				{/* <form autoComplete="off" action="">
					<RavenInputField
						type={`search`}
						onChange={(e: {
							target: { value: React.SetStateAction<string> };
						}) => setQ(e.target.value)}
						className="global-search"
						color={'deep-green-light'}
						placeholder={`Search Bankbox...`}
					/>
					<OmniSearch q={q} open={q.length >= 1} />
				</form> */}
			</div>
			{/* search box end */}
			{/* api documenttation box start */}
			<div className="api-documentation-box light-dull-border-color">
				<a
					href="https://raven-bankbox.readme.io/reference/get-profile"
					target="_blank"
					rel="noopener noreferrer"
				>
					<p className="text">API Documentation</p>
				</a>
				<figure className="img-box">{apiArrowIcon}</figure>
			</div>
			{/* api documenttation box end */}
			{/* theme box start */}
			<div className="theme-switch-box grey-fade-white-bg">
				{/* switch start */}
				<div
					onClick={() => {
						theme === 'dark' && toggleTheme && toggleTheme('light');
					}}
					className={`switch ${
						theme !== 'dark' && 'switch-active white-dull-white-bg'
					}`}
				>
					<figure className="img-box">
						{theme !== 'dark' ? lightIcon : lightIconTwo}
					</figure>
				</div>
				{/* switch end */}
				{/* switch start */}
				<div
					onClick={() => {
						theme === 'light' && toggleTheme && toggleTheme('dark');
					}}
					className={`switch ${
						theme === 'dark' && 'switch-active white-dull-white-bg'
					}`}
				>
					<figure className="img-box">
						{theme !== 'dark' ? darkIcon : darkIconTwo}
					</figure>
				</div>
				{/* switch end */}
			</div>
			{/* theme box end */}
			{/* mode box start */}
			{/* <div className="mode-box light-dull-border-color">
				<div className="color-box"></div>
				<p className="text">Test Mode</p>
				<div className="icon-box white-fade-white-bg">
					<FaAngleDown className="icon" />
				</div>
			</div> */}
			{/* mode box end */}
			{/* profile avatar box start */}
			<div className="profile-avatar-box">
				{profileImg ? (
					<figure className="img-box">
						<img src="" alt="" className="img" />
					</figure>
				) : (
					<div
						onClick={() => setShowDrop(!showDrop)}
						style={{
							cursor: 'pointer',
							backgroundColor:
								theme !== 'dark' ? generateRandomColor('#e8fff6') : '',
						}}
						className="avatar-box"
					>
						<DropMore className={showDrop && 'show-drop profile-drop'} />{' '}
						<span className="deep-green-white-color">
							{returnInitial(
								profile?.user?.first_name ||
									'' + ' ' + profile?.user?.last_name ||
									''
							)}
						</span>
					</div>
				)}
			</div>
			{/* profile avatar box end */}
		</div>
	);
};

export default HeaderNavComponent;
