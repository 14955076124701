import React, { useEffect, useState } from 'react';
import './styles/AcceptInvitePage.css';
import { inviteIcon } from './inviteIcons';
import NoContentBox from '../../../components/no-content/NoContentBox';
import ReuseableDescription from './ReuseableDescription';
import BadgeComponent from '../../../../../../components/common/badge/BadgeComponent';
import { RavenInputField, toast } from '@ravenpay/raven-bank-ui';
import { bankboxAPI } from '../../../../../../redux/bankbox';
import { useDispatch } from 'react-redux';
import {
	capitalizeFirstLetter,
	trimLongString,
} from '../../../../../../utils/helper/Helper';
import {
	validatePasswordLowercase,
	validatePasswordNumber,
	validatePasswordSpecialCharacter,
	validatePasswordUpperCase,
} from '../../../../../../utils/helper/ValidateInput';

const AcceptInvitePage = () => {
	const [status, setStatus] = useState<any>('1');
	const [loading, setLoading] = useState<boolean>(true);
	const dispatch = useDispatch();
	type formProps = {
		new_password: string;
		confirm_password: string;
	};
	const [details, setDetails] = useState<formProps>({
		new_password: '',
		confirm_password: '',
	});

	const handleChange = (e: any) => {
		const { name, value } = e.target;
		const obj = { ...details, [name]: value };
		setDetails(obj);
	};
	const [loadingAccept, setLoadingAccept] = useState(false);
	const handleSubmit = async () => {
		const obj = {
			password: details?.new_password,
			invitation_token: window?.location?.href?.split('?ref=')[1],
			confirm_password: details?.confirm_password,
		};
		if (!validatePasswordLowercase(details?.new_password)) {
			toast.error('New Password should have at least 1 lowercase letter', {
				position: 'top-right',
			});
			return;
		}
		if (!validatePasswordUpperCase(details?.new_password)) {
			toast.error('New Password should have at least 1 capital letter', {
				position: 'top-right',
			});
			// setError((prev) => {
			//   return { ...prev, password: true };
			// });
			return;
		}
		if (!validatePasswordSpecialCharacter(details?.new_password)) {
			toast.error('New Password should have at least 1 special character', {
				position: 'top-right',
			});
			return;
		}
		if (!validatePasswordNumber(details?.new_password)) {
			toast.error('New Password should have at least  1 numeric character.', {
				position: 'top-right',
			});
			return;
		}
		if (details?.new_password !== details?.confirm_password) {
			toast.error("Passwords doesn't match.", {
				position: 'top-right',
			});
			return;
		}
		setLoadingAccept(true);
		const data = await dispatch(bankboxAPI?.acceptInviteTeamMember(obj));

		if (data?.payload?.status === 'success') {
			setLoadingAccept(false);
			setStatus('2');
		} else {
			setLoadingAccept(false);
		}
	};

	useEffect(() => {
		const ref = window?.location?.href?.split('?ref=')[1];
		// console.log(ref);
		if (ref) {
			getDetails(ref);
		} else {
			setStatus('0');
		}
	}, []);
	const [detail, setDetail] = useState<any>({});
	const getDetails = async (param: any) => {
		const obj = {
			invitation_token: param,
		};
		setLoading(true);
		const data = await dispatch(bankboxAPI?.getInvitationDetails(obj));
		// console.log(data);
		if (data?.payload?.data === 'invalid token') {
			setLoading(false);
			setStatus('0');
			return;
		}

		if (data?.payload?.status === 'success') {
			// console.log(data?.payload);
			const obj = data?.payload?.data?.data;
			if (obj?.status === 'Accepted') {
				setStatus('2');
			} else if (obj?.status === 'Pending') {
				setStatus('1');
				setDetail(obj);
			} else {
				setLoading(false);
				setStatus('4');
			}
			setLoading(false);
			return;
		} else {
			setLoading(false);
			setStatus('4');
		}
	};

	// const hand

	return (
		<div className="accept-invite-page-index-wrap-box">
			{loading ? (
				<>
					<NoContentBox loading={true} />
				</>
			) : (
				<div className="middle-content">
					<div className="img-logo-box">
						<figure className="img-box">{inviteIcon?.logo}</figure>
					</div>
					{/* form wrap start */}
					<div className="content-wrap">
						{/* unknown phase box start */}
						{status === '4' && (
							<>
								<ReuseableDescription
									title="This invite link is expired"
									text="The invitation link has expired or has been accepted. Please request a new one or contact support for help."
								/>
							</>
						)}
						{/* unknown phase box end */}
						{/* form content start */}
						{status === '1' && (
							<div className="form-content">
								<p className="form-title">{`Hi ${capitalizeFirstLetter(
									detail?.first_name
								)},`}</p>
								<p className="form-text">
									Please complete registeration to access the BankBox Console.
								</p>
								{/* badge box start */}
								<div className="badge-two-role-mail-box">
									<BadgeComponent
										text={trimLongString(detail?.email, 25)?.toLowerCase()}
										className="info"
										noCap
									/>
									<BadgeComponent
										className={`pending ${
											detail?.role === 'administrator'
												? 'purple'
												: detail?.role === 'owner'
												? `success`
												: detail?.role === 'support'
												? `pending`
												: detail?.role === 'finance'
												? `info`
												: 'pending'
										}`}
										text={trimLongString(detail?.role, 25)}
									/>
								</div>
								{/* badge box end */}
								{/* actual form start */}
								<form
									action=""
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit();
									}}
									className="form"
									autoComplete="off"
								>
									<RavenInputField
										type="password"
										placeholder="Enter new Password"
										label="New Password"
										color="deep-green-light"
										showPasswordStrength={true as any}
										name="new_password"
										onChange={handleChange}
										value={details?.new_password}
									/>
									<RavenInputField
										type="password"
										placeholder="Confirm new Password"
										label="Confirm Password"
										color="deep-green-light"
										name="confirm_password"
										onChange={handleChange}
										value={details?.confirm_password}
									/>
									<RavenInputField
										type="submit"
										disabled={
											!details?.confirm_password || !details?.new_password
										}
										className="btn"
										color="deep-green-light"
										value={`Complete Registration`}
										loading={loadingAccept}
									/>
								</form>
								{/* actual form end */}
							</div>
						)}
						{/* form content end */}
						{/* unknown phase box start */}
						{status === '2' && (
							<>
								<ReuseableDescription
									type="success"
									title="Team Invitation Accepted Successfully"
									text="Thank you for joining! You now 
                  have full access to collaborate and 
                  contribute to the team."
								/>
							</>
						)}
						{/* unknown phase box end */}
						{/* unknown phase box start */}
						{status === '0' && (
							<>
								<ReuseableDescription
									type="wrong"
									title="Looks like you might have the wrong address"
									text="It seems that you may have mistakenly used the wrong address. Could you please double-check the details to ensure  everything is correct?."
								/>
							</>
						)}
						{/* unknown phase box end */}
					</div>
					{/* form wrap end */}
					{/* powered by wrap start */}
					<div className="powered-by-box">
						<div className="icon-box">
							<figure className="img-box">{inviteIcon?.poweredBy}</figure>
						</div>
						<p className="text">Powered by Beststar MFB</p>
					</div>
					{/* powered by wrap end */}
				</div>
			)}
		</div>
	);
};

export default AcceptInvitePage;
